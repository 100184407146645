import { render, staticRenderFns } from "./EducationMore.vue?vue&type=template&id=6fb5c367&scoped=true&"
import script from "./EducationMore.vue?vue&type=script&lang=js&"
export * from "./EducationMore.vue?vue&type=script&lang=js&"
import style0 from "./EducationMore.vue?vue&type=style&index=0&id=6fb5c367&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb5c367",
  null
  
)

export default component.exports